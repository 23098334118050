// src/Users.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import SearchBar from "../components/SearchBar";

const Users = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isError, user } = useSelector((state) => state.auth);

	const [users, setUsers] = useState([]);
	const [searchQuery, setSearchQuery] = useState(""); // State untuk pencarian
	const [formData, setFormData] = useState({
		uuid: null,
		name: "",
		email: "",
		password: "",
		confPassword: "",
		role: "",
	});
	const [msg, setMsg] = useState("");
	const [isEdit, setIsEdit] = useState(false);

	const API_URL = "http://localhost:5000/users"; // Sesuaikan dengan URL backend Anda

	// Mengambil data pengguna saat pertama kali render
	useEffect(() => {
		dispatch(getMe());
	}, [dispatch]);

	// Menavigasi ke halaman utama jika terjadi error autentikasi
	useEffect(() => {
		if (isError) {
			navigate("/");
		}
	}, [isError, navigate]);

	// Mengambil data pengguna dari server
	useEffect(() => {
		fetchUsers();
	}, []);

	const fetchUsers = async () => {
		try {
			const response = await axios.get(API_URL);
			setUsers(response.data);
		} catch (error) {
			setMsg(error.response?.data?.msg || "Gagal mengambil data pengguna");
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setMsg("");
		if (isEdit) {
			// Update user
			try {
				await axios.patch(`${API_URL}/${formData.uuid}`, {
					name: formData.name,
					email: formData.email,
					password: formData.password,
					confPassword: formData.confPassword,
					role: formData.role,
				});
				setMsg("User berhasil diperbarui");
				fetchUsers();
				resetForm();
			} catch (error) {
				setMsg(error.response?.data?.msg || "Gagal memperbarui user");
			}
		} else {
			// Tambah user
			try {
				await axios.post(API_URL, {
					name: formData.name,
					email: formData.email,
					password: formData.password,
					confPassword: formData.confPassword,
					role: formData.role,
				});
				setMsg("User berhasil ditambahkan");
				fetchUsers();
				resetForm();
			} catch (error) {
				setMsg(error.response?.data?.msg || "Gagal menambahkan user");
			}
		}
	};

	const handleEdit = (user) => {
		setIsEdit(true);
		setFormData({
			uuid: user.uuid,
			name: user.name,
			email: user.email,
			password: "",
			confPassword: "",
			role: user.role,
		});
		setMsg("");
	};

	const handleDelete = async (uuid) => {
		if (window.confirm("Apakah Anda yakin ingin menghapus user ini?")) {
			try {
				await axios.delete(`${API_URL}/${uuid}`);
				setMsg("User berhasil dihapus");
				fetchUsers();
			} catch (error) {
				setMsg(error.response?.data?.msg || "Gagal menghapus user");
			}
		}
	};

	const resetForm = () => {
		setIsEdit(false);
		setFormData({
			uuid: null,
			name: "",
			email: "",
			password: "",
			confPassword: "",
			role: "",
		});
	};

	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSearchChange = (query) => {
		setSearchQuery(query);
	};

	const filteredUsers = users.filter((user) => {
		const lowerCaseQuery = searchQuery.toLowerCase();
		return Object.values(user).some((value) => String(value).toLowerCase().includes(lowerCaseQuery));
	});

	return (
		<Layout>
			<main className="nxl-container">
				<div className="nxl-content">
					{/* [ page-header ] start */}
					<div className="page-header">
						<div className="page-header-left d-flex align-items-center">
							<div className="page-header-title">
								<h5 className="m-b-10">Users</h5>
							</div>
							<ul className="breadcrumb">
								<li className="breadcrumb-item">
									<a href="/dashboard">Home</a>
								</li>
								<li className="breadcrumb-item">Users</li>
							</ul>
						</div>
					</div>
					<div id="collapseOne" className="accordion-collapse collapse page-header-collapse">
						<div className="accordion-body pb-2">
							<div className="row">
								<div className="col-xxl-3 col-md-6">
									<div className="card stretch stretch-full">
										<div className="card-body">
											<div className="d-flex align-items-center justify-content-between">
												<a href="javascript:void(0);" className="fw-bold d-block">
													<span className="d-block">Paid</span>
													<span className="fs-20 fw-bold d-block">78/100</span>
												</a>
												<div className="progress-1" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-xxl-3 col-md-6">
									<div className="card stretch stretch-full">
										<div className="card-body">
											<div className="d-flex align-items-center justify-content-between">
												<a href="javascript:void(0);" className="fw-bold d-block">
													<span className="d-block">Unpaid</span>
													<span className="fs-20 fw-bold d-block">38/50</span>
												</a>
												<div className="progress-2" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-xxl-3 col-md-6">
									<div className="card stretch stretch-full">
										<div className="card-body">
											<div className="d-flex align-items-center justify-content-between">
												<a href="javascript:void(0);" className="fw-bold d-block">
													<span className="d-block">Overdue</span>
													<span className="fs-20 fw-bold d-block">15/30</span>
												</a>
												<div className="progress-3" />
											</div>
										</div>
									</div>
								</div>
								<div className="col-xxl-3 col-md-6">
									<div className="card stretch stretch-full">
										<div className="card-body">
											<div className="d-flex align-items-center justify-content-between">
												<a href="javascript:void(0);" className="fw-bold d-block">
													<span className="d-block">Draft</span>
													<span className="fs-20 fw-bold d-block">3/10</span>
												</a>
												<div className="progress-4" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* [ page-header ] end */}
					{/* [ Main Content ] start */}
					<div className="main-content">
						<div className="row">
							<div className="col-lg-12">
								<div className="card stretch stretch-full">
									<div className="card-body p-0">
										<div className="table-responsive">
											<SearchBar searchQuery={searchQuery} onSearchChange={handleSearchChange} />
											<table className="table table-hover">
												<thead>
													<tr>
														<th>No</th>
														<th>Nama</th>
														<th>Email</th>
														<th>Password</th>
														<th>Confirm Password</th>
														<th>Role</th>
														{user && user.role === "admin" && <th>Actions</th>}
													</tr>
												</thead>
												<tbody>
													{user && user.role === "admin" && (
														<tr>
															<td>NO</td>
															<td>
																<input
																	type="text"
																	className="form-control"
																	name="name"
																	value={formData.name}
																	onChange={handleInputChange}
																	placeholder="Name"
																	required
																/>
															</td>
															<td>
																<input
																	type="email"
																	className="form-control"
																	name="email"
																	value={formData.email}
																	onChange={handleInputChange}
																	placeholder="Email"
																	required
																/>
															</td>
															<td>
																<input
																	type="password"
																	className="form-control"
																	name="password"
																	value={formData.password}
																	onChange={handleInputChange}
																	placeholder={isEdit ? "Biarkan kosong jika tidak diubah" : "******"}
																	required={!isEdit}
																/>
															</td>
															<td>
																<input
																	type="password"
																	className="form-control"
																	name="confPassword"
																	value={formData.confPassword}
																	onChange={handleInputChange}
																	placeholder={isEdit ? "Biarkan kosong jika tidak diubah" : "******"}
																	required={!isEdit}
																/>
															</td>
															<td>
																<select className="form-select" name="role" value={formData.role} onChange={handleInputChange} required>
																	<option value="">Pilih Role</option>
																	<option value="admin">Admin</option>
																	<option value="user">User</option>
																</select>
															</td>
															<td>
																<button onClick={handleSubmit} className="btn btn-primary">
																	{isEdit ? "Update" : "Tambah"}
																</button>
															</td>
														</tr>
													)}
													{filteredUsers.map((userItem, index) => (
														<tr key={userItem.uuid}>
															<td>{index + 1}</td>
															<td>{userItem.name}</td>
															<td>{userItem.email}</td>
															<td>******</td>
															<td>******</td>
															<td>{userItem.role}</td>
															{user && user.role === "admin" && (
																<td>
																	<button className="btn btn-warning btn-sm me-2" onClick={() => handleEdit(userItem)}>
																		Edit
																	</button>
																	<button className="btn btn-danger btn-sm" onClick={() => handleDelete(userItem.uuid)}>
																		Delete
																	</button>
																</td>
															)}
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* [ Main Content ] end */}
				</div>
				{/* [ Footer ] start */}
				<footer className="footer">
					<p className="fs-11 text-muted fw-medium text-uppercase mb-0 copyright">
						<span>Copyright ©</span>
					</p>
					<div className="d-flex align-items-center gap-4">
						<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
							Help
						</a>
						<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
							Terms
						</a>
						<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
							Privacy
						</a>
					</div>
				</footer>
				{/* [ Footer ] end */}
			</main>
		</Layout>
	);
};

export default Users;
