import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, reset } from "../features/authSlice";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user, isError, isSuccess, isLoading, message } = useSelector((state) => state.auth);

	useEffect(() => {
		if (user || isSuccess) {
			navigate("/dashboard");
		}
		dispatch(reset());
	}, [user, isSuccess, dispatch, navigate]);

	const Auth = (e) => {
		e.preventDefault();
		dispatch(LoginUser({ email, password }));
	};

	return (
		<div>
			<main className="auth-minimal-wrapper">
				<div className="auth-minimal-inner">
					<div className="minimal-card-wrapper">
						<div className="card mb-4 mt-5 mx-4 mx-sm-0 position-relative">
							<div className="wd-50 bg-white p-2 rounded-circle shadow-lg position-absolute translate-middle top-0 start-50">
								<img src="./assets/images/14.png" alt className="img-fluid" />
							</div>
							<div className="card-body p-sm-5">
								<h2 className="fs-20 fw-bolder mb-4">Login</h2>
								<h4 className="fs-13 fw-bold mb-2">Login to your account</h4>
								{isError && <p className="has-text-centered">{message}</p>}
								<form onSubmit={Auth} className="w-100 mt-4 pt-2">
									<div className="mb-4">
										<input
											type="email"
											className="form-control"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											placeholder="Email"
											required
										/>
									</div>
									<div className="mb-3">
										<input
											type="password"
											className="form-control"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											placeholder="******"
											required
										/>
									</div>
									<div className="mt-5">
										<button type="submit" className="btn btn-lg btn-primary w-100">
											{isLoading ? "Loading..." : "Login"}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</main>
			{/*! ================================================================ !*/}
			{/*! [End] Main Content !*/}
			{/*! ================================================================ !*/}
			{/*! ================================================================ !*/}
		</div>
	);
};

export default Login;
