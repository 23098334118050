import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import Products from "./pages/Products";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddProduct from "./pages/AddProduct";
import EditProduct from "./pages/EditProduct";
import BahanJadi from "./pages/bahanjadi";
import BahanKotor from "./pages/penerimaanbahankotor";
import BahanBersih from "./pages/penerimaanbahanbersih";
import PengeluaranBahanKotor from "./pages/pengeluaranbahankotor";
import PengeluaranBahanBersih from "./pages/pengeluaranbahanbersih";
import Pencucian1 from "./pages/pencucian1";
import Pencucian2 from "./pages/pencucian2";
import Pencabutan from "./pages/pencabutan";
import CriticalControl1 from "./pages/criticalcontrol1";
import CriticalControl2 from "./pages/criticalcontrol2";
import Pencetakan from "./pages/pencetakan";
import Pengeringan from "./pages/pengeringan";
import PenyusutanBahanBersih from "./pages/penyusutanbahanbersih";
import PenyusutanBahanKotor from "./pages/penyusutanbahankotor";
import Pemanasan1 from "./pages/pemanasan1";
import Pemanasan2 from "./pages/pemanasan2";
import Pengradingan from "./pages/pengradingan";

// Impor JavaScript Vendors

// Impor JavaScript untuk inisialisasi aplikasi

function App() {
	return (
		<div>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/users" element={<Users />} />
					<Route path="/users/add" element={<AddUser />} />
					<Route path="/users/edit/:id" element={<EditUser />} />
					<Route path="/products" element={<Products />} />
					<Route path="/products/add" element={<AddProduct />} />
					<Route path="/products/edit/:id" element={<EditProduct />} />
					<Route path="/bahanjadi" element={<BahanJadi />} />
					<Route path="/CriticalControl1" element={<CriticalControl1 />} />
					<Route path="/CriticalControl2" element={<CriticalControl2 />} />
					<Route path="/bahankotor" element={<BahanKotor />} />
					<Route path="/bahanbersih" element={<BahanBersih />} />
					<Route path="/pengeluaranbahankotor" element={<PengeluaranBahanKotor />} />
					<Route path="/pengeluaranbahanbersih" element={<PengeluaranBahanBersih />} />
					<Route path="/pencucian1" element={<Pencucian1 />} />
					<Route path="/pencucian2" element={<Pencucian2 />} />
					<Route path="/pencabutan" element={<Pencabutan />} />
					<Route path="/pengradingan" element={<Pengradingan />} />
					<Route path="/pencetakan" element={<Pencetakan />} />
					<Route path="/pengeringan" element={<Pengeringan />} />
					<Route path="/penyusutanbahanbersih" element={<PenyusutanBahanBersih />} />
					<Route path="/penyusutanbahankotor" element={<PenyusutanBahanKotor />} />
					<Route path="/pemanasan1" element={<Pemanasan1 />} />
					<Route path="/pemanasan2" element={<Pemanasan2 />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
