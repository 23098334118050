import React from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const ExcelExport = ({ data, fileName }) => {
	const exportToExcel = (e) => {
		e.preventDefault();

		// Buat worksheet dari data JSON
		const worksheet = XLSX.utils.json_to_sheet(data);

		// Tambahkan style untuk semua cell
		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		for (let R = range.s.r; R <= range.e.r; ++R) {
			for (let C = range.s.c; C <= range.e.c; ++C) {
				const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
				if (!worksheet[cellRef]) continue;

				worksheet[cellRef].s = {
					border: {
						top: { style: "thin", color: { rgb: "000000" } },
						bottom: { style: "thin", color: { rgb: "000000" } },
						left: { style: "thin", color: { rgb: "000000" } },
						right: { style: "thin", color: { rgb: "000000" } },
					},
				};
			}
		}

		// Buat workbook baru dan tambahkan worksheet
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

		// Tulis workbook ke buffer
		const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

		// Buat blob dari buffer
		const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

		// Trigger download file
		saveAs(blob, `${fileName}.xlsx`);
	};

	return (
		<a href="#" onClick={exportToExcel} className="dropdown-item d-flex align-items-center">
			<i class="bi bi-filetype-exe me-3"></i>
			<span>Excel</span>
		</a>
	);
};

export default ExcelExport;
