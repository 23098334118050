import React from "react";
import { useSelector } from "react-redux";

const Welcome = () => {
	const { user } = useSelector((state) => state.auth);
	return (
		<main className="nxl-container">
			<div className="nxl-content">
				{/* [ page-header ] start */}
				<div className="page-header">
					<div className="page-header-left d-flex align-items-center">
						<div className="page-header-title">
							<h5 className="m-b-10">Dashboard</h5>
						</div>
						<ul className="breadcrumb">
							<li className="breadcrumb-item">
								<a href="/dashboard">Home</a>
							</li>
							<li className="breadcrumb-item">Dashboard</li>
						</ul>
					</div>
				</div>
				{/* [ page-header ] end */}
				{/* [ Main Content ] start */}
				<div className="main-content">
					<div className="row">
						{/* [Penerimaan Bahan Kotor] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/bahankotor">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Penerimaan Bahan Kotor</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Penerimaan Bahan Kotor] end */}
						{/* [Penerimaan Bahan Bersih] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/bahanbersih">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Penerimaan Bahan Bersih</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Penerimaan Bahan Bersih] end */}
						{/* [Pengeluaran Bahan Kotor] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/pengeluaranbahankotor">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Pengeluaran Bahan Kotor</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Pengeluaran Bahan Kotor] end */}
						{/* [Pengeluaran Bahan Bersih] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/pengeluaranbahanbersih">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Pengeluaran Bahan Bersih</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Pengeluaran Bahan Bersih] end */}
						{/* [Pencucian 1] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/pencucian1">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Pencucian 1</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Pencucian 1] end */}
						{/* [Pencucian 2] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/pencucian2">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Pencucian 2</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Pencucian 2] end */}
						{/* [Pencabutan] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/pencabutan">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Pencabutan</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Pencabutan] end */}
						{/* [Pencetakan] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/pencetakan">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Pencetakan</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Pencetakan] end */}
						{/* [Critical Control Point 1] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/CriticalControl1">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Critical Control Point 1</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Critical Control Point 1] end */}
						{/* [Critical Control Point 2] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/CriticalControl2">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Critical Control Point2</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Critical Control Point 2] end */}
						{/* [Pengeringan] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/pengeringan">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Pengeringan</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Pengeringan] end */}
						{/* [Penyusutan Bahan Kotor] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/penyusutanbahankotor">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Penyusutan Bahan Kotor</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Penyusutan Bahan Kotor] end */}
						{/* [Penyusutan Bahan Bersih] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/penyusutanbahanbersih">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Penyusutan Bahan Bersih</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Penyusutan Bahan Bersih] end */}
						{/* [Penggrandingan] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/pengradingan">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">PenGradingan</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Penggrandingan] end */}
						{/* [Bahan Jadi] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/bahanjadi">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Bahan Jadi</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Bahan Jadi] end */}
						{/* [Pemanasan 1] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/pemanasan1">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Pemanasan 1</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Pemanasan 1] end */}
						{/* [Pemanasan 2] start */}
						<div className="col-xxl-3 col-md-6">
							<a href="/pemanasan2">
								<div
									className="card stretch stretch-full"
									style={{ backgroundColor: "#ffffff", transition: "background-color 0.3s" }}
									onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f5")}
									onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
								>
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between">
											<div className="d-flex gap-4 align-items-center">
												<div className="avatar-text avatar-lg bg-gray-200">
													<i className="feather-dollar-sign" />
												</div>
												<div>
													<h3 className="fs-13 fw-semibold ">Pemanasan 2</h3>
												</div>
											</div>
											<a href="javascript:void(0);" className>
												<i className="feather-more-vertical" />
											</a>
										</div>
									</div>
								</div>
							</a>
						</div>
						{/* [Pemanasan 2] end */}
					</div>
				</div>
				{/* [ Main Content ] end */}
			</div>
			{/* [ Footer ] start */}
			<footer className="footer">
				<p className="fs-11 text-muted fw-medium text-uppercase mb-0 copyright">
					<span>Copyright ©</span>
				</p>
				<div className="d-flex align-items-center gap-4">
					<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
						Help
					</a>
					<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
						Terms
					</a>
					<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
						Privacy
					</a>
				</div>
			</footer>
			{/* [ Footer ] end */}
		</main>
	);
};

export default Welcome;
