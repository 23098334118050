// src/components/Header.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";

const Navbar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);

	const logout = () => {
		dispatch(LogOut());
		dispatch(reset());
		navigate("/");
	};
	return (
		<header className="nxl-header">
			<div className="header-wrapper">
				<div className="header-left d-flex align-items-center gap-4">
					<a href="javascript:void(0);" className="nxl-head-mobile-toggler" id="mobile-collapse">
						<div className="hamburger hamburger--arrowturn">
							<div className="hamburger-box">
								<div className="hamburger-inner" />
							</div>
						</div>
					</a>
					<div className="nxl-navigation-toggle">
						<a href="javascript:void(0);" id="menu-mini-button">
							<i className="feather-align-left" />
						</a>
						<a href="javascript:void(0);" id="menu-expend-button" style={{ display: "none" }}>
							<i className="feather-arrow-right" />
						</a>
					</div>
					<div className="nxl-lavel-mega-menu-toggle d-flex d-lg-none">
						<a href="javascript:void(0);" id="nxl-lavel-mega-menu-open">
							<i className="feather-align-left" />
						</a>
					</div>
					<div className="nxl-drp-link nxl-lavel-mega-menu">
						<div className="nxl-lavel-mega-menu-toggle d-flex d-lg-none">
							<a href="javascript:void(0)" id="nxl-lavel-mega-menu-hide">
								<i className="feather-arrow-left me-2" />
								<span>Back</span>
							</a>
						</div>
						<div className="nxl-lavel-mega-menu-wrapper d-flex gap-3"></div>
					</div>
				</div>
				<div className="header-right ms-auto">
					<div className="d-flex align-items-center">
						<div className="nxl-h-item dark-light-theme">
							<a href="javascript:void(0);" className="nxl-head-link me-0 dark-button">
								<i className="feather-moon" />
							</a>
							<a href="javascript:void(0);" className="nxl-head-link me-0 light-button" style={{ display: "none" }}>
								<i className="feather-sun" />
							</a>
						</div>
						<div className="dropdown nxl-h-item">
							<a href="javascript:void(0);" data-bs-toggle="dropdown" role="button" data-bs-auto-close="outside">
								{user && user.name}
							</a>
							<div className="dropdown-menu dropdown-menu-end nxl-h-dropdown nxl-user-dropdown">
								<div className="dropdown-header">
									<div className="d-flex align-items-center">
										<FaUser className="img-fluid user-avtar" />

										<div>
											<h6 className="text-dark mb-0">
												{user && user.name} | {user && user.role}
											</h6>
											<span className="fs-12 fw-medium text-muted">{user && user.email}</span>
										</div>
									</div>
								</div>

								<div className="dropdown-divider" />
								<button onClick={logout} class="dropdown-item">
									<i class="feather-log-out"></i>
									Log out
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Navbar;
