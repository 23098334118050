import React from "react";

const SearchBar = ({ searchQuery, onSearchChange }) => {
	return (
		<div className="input-group flex-nowrap">
			<span className="input-group-text" id="addon-wrapping">
				<i class="bi bi-search"></i>
			</span>
			<input
				type="text"
				className="form-control"
				placeholder="Cari Data"
				value={searchQuery}
				onChange={(e) => onSearchChange(e.target.value)}
				aria-label="Default"
				aria-describedby="inputGroup-sizing-default"
			/>
		</div>
	);
};

export default SearchBar;
