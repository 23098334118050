import React from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const PdfExport = ({ data, fileName }) => {
	const exportToPDF = () => {
		const doc = new jsPDF("landscape");

		// Set text alignment to center
		doc.setFont(undefined, "bold");
		doc.setFontSize(14);
		const pageWidth = doc.internal.pageSize.getWidth();
		doc.text(fileName, pageWidth / 2, 20, { align: "center" });

		const tableColumn = Object.keys(data[0]);
		const tableRows = data.map((row) => Object.values(row));

		doc.autoTable({
			startY: 30,
			head: [tableColumn],
			body: tableRows,
			styles: { fontSize: 8, lineWidth: 0.1 },
			headStyles: { fillColor: [22, 160, 133] },
			tableLineWidth: 0.1,
			tableLineColor: [0, 0, 0],
		});

		doc.save(`${fileName}.pdf`);
	};

	return (
		<a href="#" className="dropdown-item d-flex align-items-center" onClick={exportToPDF} role="button" tabIndex={0}>
			<i className="bi bi-filetype-pdf me-3"></i>
			<span>PDF</span>
		</a>
	);
};

export default PdfExport;
