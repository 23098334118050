import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import SearchBar from "../components/SearchBar";
import ExcelExport from "./excel";
import PdfExport from "./pdf";

const Pencetakan = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isError, user } = useSelector((state) => state.auth);

	const [data, setData] = useState([]);
	const [searchQuery, setSearchQuery] = useState(""); // State untuk pencarian
	const [newData, setNewData] = useState({
		id: null, // ID untuk mendukung edit
		tanggalInput: "",
		tanggalProses: "",
		tanggalProduksi: "",
		grade: "",
		jumlah_keping: "",
		tanggalProsesCuci: "",
		pic_pencetak: "",
		supervisor_pencetak: "",
	});

	// Mengambil data pengguna saat pertama kali render
	useEffect(() => {
		dispatch(getMe());
	}, [dispatch]);

	// Menavigasi ke halaman utama jika terjadi error autentikasi
	useEffect(() => {
		if (isError) {
			navigate("/");
		}
	}, [isError, user, navigate]);

	// Mengambil data dari server
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get("http://localhost:5000/data_cetak");
				setData(response.data);
			} catch (error) {
				console.error("There was an error fetching the data!", error);
			}
		};

		fetchData();
	}, []);

	const handleInputChange = (e) => {
		setNewData({
			...newData,
			[e.target.name]: e.target.value,
		});
	};
	const generateNewId = (data) => {
		// Ambil semua ID dan cari ID dengan angka terbesar
		const idNumbers = data.map((item) => parseInt(item.id.split("-")[2])).filter((num) => !isNaN(num)); // Pastikan hanya angka yang valid

		const maxIdNumber = idNumbers.length > 0 ? Math.max(...idNumbers) : 0;
		const newNumber = maxIdNumber + 1;

		// Format ID baru dengan padding 2 angka di belakang
		return `TRI-BK-${String(newNumber).padStart(2, "0")}`;
	};
	const handleAddOrUpdate = () => {
		if (newData.id) {
			if (user.role === "admin") {
				// Update data jika peran pengguna adalah admin
				axios
					.put(`http://localhost:5000/data_cetak/${newData.id}`, newData)
					.then((response) => {
						setData(data.map((item) => (item.id === newData.id ? newData : item)));
						resetForm();
					})
					.catch((error) => {
						console.error("There was an error updating the data!", error);
					});
			} else {
				alert("Anda tidak memiliki izin untuk mengubah data");
			}
		} else {
			if (user.role !== "admin") {
				alert("Anda tidak memiliki izin untuk menambah data");
				return;
			} else {
				// Menambah data baru dengan ID otomatis
				const id = generateNewId(data); // Panggil fungsi untuk ID baru
				const newEntry = { ...newData, id };

				axios
					.post("http://localhost:5000/data_cetak", newEntry)
					.then((response) => {
						setData([...data, newEntry]);
						resetForm();
					})
					.catch((error) => {
						console.error("There was an error adding the data!", error);
					});
			}
		}
	};

	const handleEdit = (item) => {
		setNewData(item); // Set data ke form untuk diedit
	};

	const handleDelete = (id) => {
		if (user.role === "admin") {
			axios
				.delete(`http://localhost:5000/data_cetak/${id}`)
				.then((response) => {
					setData(data.filter((item) => item.id !== id));
				})
				.catch((error) => {
					console.error("There was an error deleting the data!", error);
				});
		} else {
			alert("Anda tidak memiliki izin untuk menghapus data");
		}
	};

	const resetForm = () => {
		setNewData({
			id: null,
			tanggalInput: "",
			tanggalProses: "",
			tanggalProduksi: "",
			grade: "",
			jumlah_keping: "",
			tanggalProsesCuci: "",
			pic_pencetak: "",
			supervisor_pencetak: "",
		});
	};
	const handleSearchChange = (query) => {
		setSearchQuery(query);
	};
	const filteredData = data.filter((item) => {
		// Convert search query to lowercase once
		const lowerCaseQuery = searchQuery.toLowerCase();

		// Check if any value in the item includes the search query
		return Object.values(item).some((value) => String(value).toLowerCase().includes(lowerCaseQuery));
	});

	return (
		<Layout>
			<main className="nxl-container">
				<div className="nxl-content">
					{/* [ page-header ] start */}
					<div className="page-header">
						<div className="page-header-left d-flex align-items-center">
							<div className="page-header-title">
								<h5 className="m-b-10">Pencetakan</h5>
							</div>
							<ul className="breadcrumb">
								<li className="breadcrumb-item">
									<a href="/dashboard">Home</a>
								</li>
								<li className="breadcrumb-item">Pencetakan</li>
							</ul>
						</div>
						<div className="page-header-right ms-auto">
							<div className="page-header-right-items">
								<div className="d-flex d-md-none">
									<a href="javascript:void(0)" className="page-header-right-close-toggle">
										<i className="feather-arrow-left me-2" />
										<span>Back</span>
									</a>
								</div>
							</div>
						</div>
						<div class="page-header-right ms-auto">
							<div class="page-header-right-items">
								<div class="d-flex d-md-none">
									<a href="javascript:void(0)" class="page-header-right-close-toggle">
										<i class="feather-arrow-left me-2"></i>
										<span>Back</span>
									</a>
								</div>
								<div class="d-flex align-items-center gap-2 page-header-right-items-wrapper">
									<div class="dropdown">
										<a class="btn btn-icon btn-light-brand" data-bs-toggle="dropdown" data-bs-offset="0, 10" data-bs-auto-close="outside">
											<i class="feather-paperclip"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-end">
											<PdfExport data={filteredData} fileName="Pencetakan" />
											<ExcelExport data={filteredData} fileName="Pencetakan" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* [ page-header ] end */}
					{/* [ Main Content ] start */}
					<div className="main-content">
						<div className="row">
							<div className="col-lg-12">
								<div className="card stretch stretch-full">
									<div className="card-body p-0">
										<div className="table-responsive">
											<SearchBar searchQuery={searchQuery} onSearchChange={handleSearchChange} />
											<table className="table table-hover">
												<thead>
													<tr>
														<th>ID</th>
														<th>Tanggal Input</th>
														<th>Tanggal Proses</th>
														<th>Tanggal Produksi</th>
														<th>Grade</th>
														<th>Jumlah Keping</th>
														<th>Tanggal Proses Cuci</th>
														<th>PIC Pencetak</th>
														<th>Supervisor Pencetak</th>
														{user && user.role === "admin" && <th>Actions</th>}
													</tr>
												</thead>
												<tbody>
													{user && user.role === "admin" && (
														<tr>
															<td>
																<input
																	type="text"
																	name="ID"
																	placeholder="TRI-BB-XX"
																	value={newData.id}
																	onChange={handleInputChange}
																	className="form-control"
																	disabled
																/>
															</td>
															<td>
																<input
																	type="date"
																	name="tanggalInput"
																	placeholder="Tanggal Input"
																	value={newData.tanggalInput}
																	onChange={handleInputChange}
																	className="form-control"
																	required
																/>
															</td>
															<td>
																<input
																	type="date"
																	name="tanggalProses"
																	placeholder="Tanggal Proses"
																	value={newData.tanggalProses}
																	onChange={handleInputChange}
																	className="form-control"
																	required
																/>
															</td>
															<td>
																<input
																	type="date"
																	name="tanggalProduksi"
																	placeholder="Tanggal Produksi"
																	value={newData.tanggalProduksi}
																	onChange={handleInputChange}
																	className="form-control"
																	required
																/>
															</td>
															<td>
																<input
																	type="text"
																	name="grade"
																	placeholder="Grade"
																	value={newData.grade}
																	onChange={handleInputChange}
																	className="form-control"
																	required
																/>
															</td>
															<td>
																<input
																	type="text"
																	name="jumlah_keping"
																	placeholder="Jumlah Keping"
																	value={newData.jumlah_keping}
																	onChange={handleInputChange}
																	className="form-control"
																	required
																/>
															</td>
															<td>
																<input
																	type="date"
																	name="tanggalProsesCuci"
																	placeholder="Tanggal Proses Cuci"
																	value={newData.tanggalProsesCuci}
																	onChange={handleInputChange}
																	className="form-control"
																	required
																/>
															</td>
															<td>
																<input
																	type="text"
																	name="pic_pencetak"
																	placeholder="PIC Pencetak"
																	value={newData.pic_pencetak}
																	onChange={handleInputChange}
																	className="form-control"
																	required
																/>
															</td>
															<td>
																<input
																	type="text"
																	name="supervisor_pencetak"
																	placeholder="Supervisor Pencetak"
																	value={newData.supervisor_pencetak}
																	onChange={handleInputChange}
																	className="form-control"
																	required
																/>
															</td>
															<td>
																<button onClick={handleAddOrUpdate} className="btn btn-primary">
																	{newData.id ? "Update" : "Tambah"}
																</button>
															</td>
														</tr>
													)}
													{filteredData.map((row) => (
														<tr key={row.id}>
															<td>{row.id}</td>
															<td>{new Date(row.tanggal_input).toLocaleString()}</td>
															<td>{new Date(row.tanggal_proses).toLocaleString()}</td>
															<td>{new Date(row.tanggal_produksi).toLocaleString()}</td>
															<td>{row.grade}</td>
															<td>{row.jumlah_keping}</td>
															<td>{new Date(row.tanggal_proses_cuci).toLocaleString()}</td>
															<td>{row.pic_pencetak}</td>
															<td>{row.supervisor_pencetak}</td>
															{user && user.role === "admin" && (
																<td>
																	<button className="btn btn-warning btn-sm" onClick={() => handleEdit(row)}>
																		Edit
																	</button>
																	<button className="btn btn-danger btn-sm" onClick={() => handleDelete(row.id)}>
																		Delete
																	</button>
																</td>
															)}
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* [ Main Content ] end */}
				</div>
				{/* [ Footer ] start */}
				<footer className="footer">
					<p className="fs-11 text-muted fw-medium text-uppercase mb-0 copyright">
						<span>Copyright ©</span>
					</p>
					<div className="d-flex align-items-center gap-4">
						<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
							Help
						</a>
						<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
							Terms
						</a>
						<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
							Privacy
						</a>
					</div>
				</footer>
				{/* [ Footer ] end */}
			</main>
		</Layout>
	);
};

export default Pencetakan;
