// src/components/Navbar.js
import React from "react";
import { NavLink } from "react-router-dom";
import { IoHome, IoArrowDown, IoArrowUp, IoWater, IoConstruct, IoLayers, IoCheckmark, IoThermometer, IoDocument } from "react-icons/io5";
import { FaCrosshairs, FaBox } from "react-icons/fa";
import { GiWindSlap } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
const Sidebar = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);

	return (
		<nav className="nxl-navigation">
			<div className="navbar-wrapper">
				<div className="m-header">
					<NavLink to="/dashboard" className="b-brand">
						<img src="./assets/images/13.png" alt="" className="logo logo-lg" style={{ width: "100%" }} />
						<img src="./assets/images/14.png" alt="" className="logo logo-sm" />
					</NavLink>
				</div>
				<div className="navbar-content">
					<ul className="nxl-navbar">
						<li className="nxl-item nxl-caption">
							<label>Navigation</label>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="/dashboard" className="nxl-link">
								<span className="nxl-micon">
									<IoHome />
								</span>
								<span className="nxl-mtext">Dashboards</span>
							</NavLink>
						</li>
						{user && user.role === "admin" && (
							<li className="nxl-item nxl-hasmenu">
								<NavLink to="/users" className="nxl-link">
									<span className="nxl-micon">
										<IoHome />
									</span>
									<span className="nxl-mtext">userss</span>
								</NavLink>
							</li>
						)}
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="#" className="nxl-link">
								<span className="nxl-micon">
									<IoArrowDown />
								</span>
								<span className="nxl-mtext">Penerimaan</span>
							</NavLink>
							<ul className="nxl-submenu">
								<li className="nxl-item">
									<NavLink to="/bahankotor" className="nxl-link">
										Bahan Kotor
									</NavLink>
								</li>
								<li className="nxl-item">
									<NavLink to="/bahanbersih" className="nxl-link">
										Bahan Bersih
									</NavLink>
								</li>
							</ul>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="#" className="nxl-link">
								<span className="nxl-micon">
									<IoArrowUp />
								</span>
								<span className="nxl-mtext">Pengeluaran</span>
							</NavLink>
							<ul className="nxl-submenu">
								<li className="nxl-item">
									<NavLink to="/pengeluaranbahankotor" className="nxl-link">
										Bahan Kotor
									</NavLink>
								</li>
								<li className="nxl-item">
									<NavLink to="/pengeluaranbahanbersih" className="nxl-link">
										Bahan Bersih
									</NavLink>
								</li>
							</ul>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="#" className="nxl-link">
								<span className="nxl-micon">
									<IoWater />
								</span>
								<span className="nxl-mtext">Pencucian</span>
							</NavLink>
							<ul className="nxl-submenu">
								<li className="nxl-item">
									<NavLink to="/pencucian1" className="nxl-link">
										Pencucian 1
									</NavLink>
								</li>
								<li className="nxl-item">
									<NavLink to="/pencucian2" className="nxl-link">
										Pencucian 2
									</NavLink>
								</li>
							</ul>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="/pencabutan" className="nxl-link">
								<span className="nxl-micon">
									<IoConstruct />
								</span>
								<span className="nxl-mtext">Pencabutan</span>
							</NavLink>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="#" className="nxl-link">
								<span className="nxl-micon">
									<FaCrosshairs />
								</span>
								<span className="nxl-mtext">Critical Control Point</span>
							</NavLink>
							<ul className="nxl-submenu">
								<li className="nxl-item">
									<NavLink to="/CriticalControl1" className="nxl-link">
										Critical Control Point 1
									</NavLink>
								</li>
								<li className="nxl-item">
									<NavLink to="/CriticalControl2" className="nxl-link">
										Critical Control Point 2
									</NavLink>
								</li>
							</ul>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="/pencetakan" className="nxl-link">
								<span className="nxl-micon">
									<FaBox />
								</span>
								<span className="nxl-mtext">Pencetakan</span>
							</NavLink>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="/pengeringan" className="nxl-link">
								<span className="nxl-micon">
									<GiWindSlap />
								</span>
								<span className="nxl-mtext">pengeringan</span>
							</NavLink>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="#" className="nxl-link">
								<span className="nxl-micon">
									<IoArrowDown />
								</span>
								<span className="nxl-mtext">Penyusutan</span>
							</NavLink>
							<ul className="nxl-submenu">
								<li className="nxl-item">
									<NavLink to="/penyusutanbahankotor" className="nxl-link">
										Bahan Kotor
									</NavLink>
								</li>
								<li className="nxl-item">
									<NavLink to="/penyusutanbahanbersih" className="nxl-link">
										Bahan Bersih
									</NavLink>
								</li>
							</ul>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="/pengradingan" className="nxl-link">
								<span className="nxl-micon">
									<IoLayers />
								</span>
								<span className="nxl-mtext">PenGradingan Bahan Bersih</span>
							</NavLink>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="/bahanjadi" className="nxl-link">
								<span className="nxl-micon">
									<IoCheckmark />
								</span>
								<span className="nxl-mtext">Bahan Jadi</span>
							</NavLink>
						</li>
						<li className="nxl-item nxl-hasmenu">
							<NavLink to="#" className="nxl-link">
								<span className="nxl-micon">
									<IoThermometer />
								</span>
								<span className="nxl-mtext">Pemanasan</span>
							</NavLink>
							<ul className="nxl-submenu">
								<li className="nxl-item">
									<NavLink to="/pemanasan1" className="nxl-link">
										Pemanasan 1
									</NavLink>
								</li>
								<li className="nxl-item">
									<NavLink to="/pemanasan2" className="nxl-link">
										Pemanasan 2
									</NavLink>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Sidebar;
