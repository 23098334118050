import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import ExcelExport from "./excel";
import PdfExport from "./pdf";

const PenyusutanBahanKotor = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isError, user } = useSelector((state) => state.auth);

	const [data, setData] = useState([]);
	const [newData, setNewData] = useState({
		id: null, // ID untuk mendukung edit
		tanggal_input: "",
		tanggal_produksi: "",
		tanggal_proses: "",
		gram_distribusi: "",
		jumlah_keping_distribusi: "",
		gram_pengeringan: "",
		jumlah_keping_pengeringan: "",
		gram_penyusutan: "",
		persentase_penyusutan: "",
		grade: "",
		pic_penyusutan: "",
		supervisor_penyusutan: "",
		harga_setelah_penyusutan: "",
	});

	// Mengambil data pengguna saat pertama kali render
	useEffect(() => {
		dispatch(getMe());
	}, [dispatch]);

	// Menavigasi ke halaman utama jika terjadi error autentikasi
	useEffect(() => {
		if (isError) {
			navigate("/");
		}
	}, [isError, user, navigate]);

	// Mengambil data dari server
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get("http://localhost:5000/datapenyusutanBahanKotor");
				setData(response.data);
			} catch (error) {
				console.error("There was an error fetching the data!", error);
			}
		};

		fetchData();
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewData((prevData) => {
			const updatedData = { ...prevData, [name]: value };

			// Perhitungan otomatis untuk gram_penyusutan dan persentase_penyusutan
			if (name === "gram_distribusi" || name === "gram_pengeringan") {
				const gramDistribusi = parseFloat(updatedData.gram_distribusi) || 0;
				const gramPengeringan = parseFloat(updatedData.gram_pengeringan) || 0;

				// Hitung gram penyusutan
				const gramPenyusutan = gramDistribusi - gramPengeringan;
				updatedData.gram_penyusutan = gramPenyusutan;

				// Hitung persentase penyusutan
				const persentasePenyusutan = gramDistribusi ? ((gramPengeringan / gramDistribusi) * 100).toFixed(2) - 100 : 0;
				updatedData.persentase_penyusutan = persentasePenyusutan;
			}

			return updatedData;
		});
	};

	const generateNewId = (data) => {
		// Ambil semua ID dan cari ID dengan angka terbesar
		const idNumbers = data.map((item) => parseInt(item.id.split("-")[2])).filter((num) => !isNaN(num)); // Pastikan hanya angka yang valid

		const maxIdNumber = idNumbers.length > 0 ? Math.max(...idNumbers) : 0;
		const newNumber = maxIdNumber + 1;

		// Format ID baru dengan padding 2 angka di belakang
		return `TRI-PBK-${String(newNumber).padStart(2, "0")}`;
	};

	const handleAddOrUpdate = () => {
		if (newData.id) {
			if (user.role === "admin") {
				// Update data jika peran pengguna adalah admin
				axios
					.put(`http://localhost:5000/datapenyusutanBahanKotor/${newData.id}`, newData)
					.then((response) => {
						setData(data.map((item) => (item.id === newData.id ? newData : item)));
						resetForm();
					})
					.catch((error) => {
						console.error("There was an error updating the data!", error);
					});
			} else {
				alert("Anda tidak memiliki izin untuk mengubah data");
			}
		} else {
			if (user.role !== "admin") {
				alert("Anda tidak memiliki izin untuk menambah data");
				return;
			} else {
				// Menambah data baru dengan ID otomatis
				const id = generateNewId(data); // Panggil fungsi untuk ID baru
				const newEntry = { ...newData, id };

				axios
					.post("http://localhost:5000/datapenyusutanBahanKotor", newEntry)
					.then((response) => {
						setData([...data, newEntry]);
						resetForm();
					})
					.catch((error) => {
						console.error("There was an error adding the data!", error);
					});
			}
		}
	};

	const handleEdit = (item) => {
		setNewData(item); // Menampilkan data di form untuk diedit
	};

	const handleDelete = (id) => {
		if (user.role === "admin") {
			axios
				.delete(`http://localhost:5000/datapenyusutanBahanKotor/${id}`)
				.then((response) => {
					setData(data.filter((item) => item.id !== id));
				})
				.catch((error) => {
					console.error("There was an error deleting the data!", error);
				});
		} else {
			alert("Anda tidak memiliki izin untuk menghapus data");
		}
	};

	const resetForm = () => {
		setNewData({
			id: null,
			tanggal_input: "",
			tanggal_produksi: "",
			tanggal_proses: "",
			gram_distribusi: "",
			jumlah_keping_distribusi: "",
			gram_pengeringan: "",
			jumlah_keping_pengeringan: "",
			gram_penyusutan: "",
			persentase_penyusutan: "",
			grade: "",
			pic_penyusutan: "",
			supervisor_penyusutan: "",
			harga_setelah_penyusutan: "",
		});
	};
	return (
		<Layout>
			<div>
				<main className="nxl-container">
					<div className="nxl-content">
						{/* [ page-header ] start */}
						<div className="page-header">
							<div className="page-header-left d-flex align-items-center">
								<div className="page-header-title">
									<h5 className="m-b-10">Bahan Kotor</h5>
								</div>
								<ul className="breadcrumb">
									<li className="breadcrumb-item">
										<a href="/dashboard">Home</a>
									</li>
									<li className="breadcrumb-item">Bahan Kotor</li>
								</ul>
							</div>
							<div className="page-header-right ms-auto">
								<div className="page-header-right-items">
									<div className="d-flex d-md-none">
										<a href="javascript:void(0)" className="page-header-right-close-toggle">
											<i className="feather-arrow-left me-2" />
											<span>Back</span>
										</a>
									</div>
								</div>
								<div className="d-md-none d-flex align-items-center">
									<a href="javascript:void(0)" className="page-header-right-open-toggle">
										<i className="feather-align-right fs-20" />
									</a>
								</div>
							</div>
							<div class="page-header-right ms-auto">
								<div class="page-header-right-items">
									<div class="d-flex d-md-none">
										<a href="javascript:void(0)" class="page-header-right-close-toggle">
											<i class="feather-arrow-left me-2"></i>
											<span>Back</span>
										</a>
									</div>
									<div class="d-flex align-items-center gap-2 page-header-right-items-wrapper">
										<div class="dropdown">
											<a class="btn btn-icon btn-light-brand" data-bs-toggle="dropdown" data-bs-offset="0, 10" data-bs-auto-close="outside">
												<i class="feather-paperclip"></i>
											</a>
											<div class="dropdown-menu dropdown-menu-end">
												<PdfExport data={data} fileName="Penyusutan Bahan Kotor" />
												<ExcelExport data={data} fileName="Penyusutan Bahan Kotor" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="collapseOne" className="accordion-collapse collapse page-header-collapse">
							<div className="accordion-body pb-2">
								<div className="row">
									<div className="col-xxl-3 col-md-6">
										<div className="card stretch stretch-full">
											<div className="card-body">
												<div className="d-flex align-items-center justify-content-between">
													<a href="javascript:void(0);" className="fw-bold d-block">
														<span className="d-block">Paid</span>
														<span className="fs-20 fw-bold d-block">78/100</span>
													</a>
													<div className="progress-1" />
												</div>
											</div>
										</div>
									</div>
									<div className="col-xxl-3 col-md-6">
										<div className="card stretch stretch-full">
											<div className="card-body">
												<div className="d-flex align-items-center justify-content-between">
													<a href="javascript:void(0);" className="fw-bold d-block">
														<span className="d-block">Unpaid</span>
														<span className="fs-20 fw-bold d-block">38/50</span>
													</a>
													<div className="progress-2" />
												</div>
											</div>
										</div>
									</div>
									<div className="col-xxl-3 col-md-6">
										<div className="card stretch stretch-full">
											<div className="card-body">
												<div className="d-flex align-items-center justify-content-between">
													<a href="javascript:void(0);" className="fw-bold d-block">
														<span className="d-block">Overdue</span>
														<span className="fs-20 fw-bold d-block">15/30</span>
													</a>
													<div className="progress-3" />
												</div>
											</div>
										</div>
									</div>
									<div className="col-xxl-3 col-md-6">
										<div className="card stretch stretch-full">
											<div className="card-body">
												<div className="d-flex align-items-center justify-content-between">
													<a href="javascript:void(0);" className="fw-bold d-block">
														<span className="d-block">Draft</span>
														<span className="fs-20 fw-bold d-block">3/10</span>
													</a>
													<div className="progress-4" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* [ page-header ] end */}
						{/* [ Main Content ] start */}
						<div className="main-content">
							<div className="row">
								<div className="col-lg-12">
									<div className="card stretch stretch-full">
										<div className="card-body p-0">
											<div className="table-responsive">
												<table className="table table-hover">
													<thead>
														<tr>
															<th>ID Penerimaan</th>
															<th>Tanggal Input</th>
															<th>Tanggal Produksi</th>
															<th>Tanggal Proses</th>
															<th>Gram Distribusi</th>
															<th>Jumlah Keping Distribusi</th>
															<th>Gram Pengeringan</th>
															<th>Jumlah Keping Pengeringan</th>
															<th>Gram Penyusutan</th>
															<th>Persentase Penyusutan</th>
															<th>Grade</th>
															<th>PIC Penyusutan</th>
															<th>Supervisor Penyusutan</th>
															<th>Harga Setelah Penyusutan</th>
															<th>Actions</th> {/* For edit/delete actions */}
														</tr>
													</thead>
													<tbody>
														{user && user.role === "admin" && (
															<tr>
																<td>
																	<input
																		type="text"
																		name="ID"
																		placeholder="TRI-PBK-XX"
																		value={newData.id}
																		onChange={handleInputChange}
																		className="form-control"
																		disabled
																	/>
																</td>
																<td>
																	<input
																		type="date"
																		name="tanggal_input"
																		placeholder="Tanggal Input"
																		value={newData.tanggal_input}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<input
																		type="date"
																		name="tanggal_produksi"
																		placeholder="Tanggal Produksi"
																		value={newData.tanggal_produksi}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<input
																		type="date"
																		name="tanggal_proses"
																		placeholder="Tanggal Proses"
																		value={newData.tanggal_proses}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<input
																		type="number"
																		name="gram_distribusi"
																		placeholder="Gram Distribusi"
																		value={newData.gram_distribusi}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<input
																		type="number"
																		name="jumlah_keping_distribusi"
																		placeholder="Jumlah Keping Distribusi"
																		value={newData.jumlah_keping_distribusi}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<input
																		type="number"
																		name="gram_pengeringan"
																		placeholder="Gram Pengeringan"
																		value={newData.gram_pengeringan}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<input
																		type="number"
																		name="jumlah_keping_pengeringan"
																		placeholder="Jumlah Keping Pengeringan"
																		value={newData.jumlah_keping_pengeringan}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<input
																		type="number"
																		name="gram_penyusutan"
																		placeholder="Gram Penyusutan"
																		value={newData.gram_penyusutan}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																		readOnly="!newData.gram_penyusutan" // Agar hanya terbaca saja
																	/>
																</td>
																<td>
																	<input
																		type="number"
																		name="persentase_penyusutan"
																		placeholder="Persentase Penyusutan"
																		value={newData.persentase_penyusutan}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																		readOnly // Agar hanya terbaca saja
																	/>
																</td>
																<td>
																	<input
																		type="text"
																		name="grade"
																		placeholder="Grade"
																		value={newData.grade}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<input
																		type="text"
																		name="pic_penyusutan"
																		placeholder="PIC Penyusutan"
																		value={newData.pic_penyusutan}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<input
																		type="text"
																		name="supervisor_penyusutan"
																		placeholder="Supervisor Penyusutan"
																		value={newData.supervisor_penyusutan}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<input
																		type="number"
																		name="harga_setelah_penyusutan"
																		placeholder="Harga Setelah Penyusutan"
																		value={newData.harga_setelah_penyusutan}
																		onChange={handleInputChange}
																		className="form-control"
																		style={{ width: "auto" }}
																		required
																	/>
																</td>
																<td>
																	<button onClick={handleAddOrUpdate} className="btn btn-primary">
																		{newData.id ? "Update" : "Tambah"}
																	</button>
																</td>
															</tr>
														)}
														{data.map((row) => (
															<tr key={row.id}>
																<td>{row.id}</td>
																<td>{new Date(row.tanggal_input).toLocaleString()}</td>
																<td>{new Date(row.tanggal_produksi).toLocaleString()}</td>
																<td>{new Date(row.tanggal_proses).toLocaleString()}</td>

																<td>{row.gram_distribusi}</td>
																<td>{row.jumlah_keping_distribusi}</td>
																<td>{row.gram_pengeringan}</td>
																<td>{row.jumlah_keping_pengeringan}</td>
																<td>{row.gram_penyusutan}</td>
																<td>{row.persentase_penyusutan}</td>
																<td>{row.grade}</td>
																<td>{row.pic_penyusutan}</td>
																<td>{row.supervisor_penyusutan}</td>
																<td>{row.harga_setelah_penyusutan}</td>
																<td>
																	<button className="btn btn-warning btn-sm" onClick={() => handleEdit(row)}>
																		Edit
																	</button>
																	<button className="btn btn-danger btn-sm" onClick={() => handleDelete(row.id)}>
																		Delete
																	</button>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* [ Main Content ] end */}
					</div>
					{/* [ Footer ] start */}
					<footer className="footer">
						<p className="fs-11 text-muted fw-medium text-uppercase mb-0 copyright">
							<span>Copyright ©</span>
						</p>
						<div className="d-flex align-items-center gap-4">
							<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
								Help
							</a>
							<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
								Terms
							</a>
							<a href="javascript:void(0);" className="fs-11 fw-semibold text-uppercase">
								Privacy
							</a>
						</div>
					</footer>
					{/* [ Footer ] end */}
				</main>
				{/*! ================================================================ !*/}
				{/*! [End] Main Content !*/}
				{/*! ================================================================ !*/}
				{/*! ================================================================ !*/}
				{/*! [Start] Sent Proposal l !*/}
				{/*! ================================================================ !*/}
				<div className="offcanvas offcanvas-end" tabIndex={-1} id="proposalSent">
					<div className="offcanvas-header ht-80 px-4 border-bottom border-gray-5">
						<div>
							<h2 className="fs-16 fw-bold text-truncate-1-line">Sent Proposal</h2>
							<small className="fs-12 text-muted">Sent proposal to your client's</small>
						</div>
						<button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
					</div>
					<div className="py-3 px-4 d-flex justify-content-between align-items-center border-bottom border-bottom-dashed border-gray-5 bg-gray-100">
						<div>
							<span className="fw-bold text-dark">Date:</span>
							<span className="fs-11 fw-medium text-muted">25 MAY, 2023</span>
						</div>
						<div>
							<span className="fw-bold text-dark">Proposal No:</span>
							<span className="fs-12 fw-bold text-primary c-pointer">#NXL369852</span>
						</div>
					</div>
					<div className="offcanvas-body">
						<div className="form-group mb-4">
							<label className="form-label">
								From: <span className="text-danger">*</span>
							</label>
							<input
								type="email"
								className="form-control"
								style={{ width: "auto" }}
								defaultValue="wrapcode.info@gmail.com"
								placeholder="Clients..."
								readOnly
								required
							/>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">
								To: <span className="text-danger">*</span>
							</label>
							<input
								className="form-control"
								style={{ width: "auto" }}
								name="tomailcontent"
								defaultValue="wrapcode.info@gmail.com"
								placeholder="To..."
								required
							/>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">
								Subject: <span className="text-danger">*</span>
							</label>
							<input type="text" className="form-control" placeholder="Subject..." required />
						</div>
						<div className="form-group mb-4">
							<label className="form-label">URL: </label>
							<input type="url" className="form-control" placeholder="URL..." />
						</div>
						<div className="form-group">
							<label className="form-label">Messages:</label>
							<div data-editor-target="editor" className="ht-200" />
						</div>
					</div>
					<div className="px-4 gap-2 d-flex align-items-center ht-80 border border-end-0 border-gray-2">
						<a href="javascript:void(0);" className="btn btn-primary w-50" data-alert-target="#alertMessage">
							Sent Proposal
						</a>
						<a href="javascript:void(0);" className="btn btn-danger w-50" data-bs-dismiss="offcanvas">
							Cancel
						</a>
					</div>
				</div>
				{/*! ================================================================ !*/}
				{/*! [End] Sent Proposal !*/}
				{/*! ================================================================ !*/}
				{/*! ================================================================ !*/}
				{/*! [Start] Search Modal !*/}
				{/*! ================================================================ !*/}
				<div className="modal fade-scale" id="searchModal" aria-hidden="true" tabIndex={-1}>
					<div className="modal-dialog modal-lg modal-dialog-top modal-dialog-scrollable">
						<div className="modal-content">
							<div className="modal-header search-form py-0">
								<div className="input-group">
									<span className="input-group-text">
										<i className="feather-search fs-4 text-muted" />
									</span>
									<input type="text" className="form-control search-input-field" placeholder="Search..." />
									<span className="input-group-text">
										<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
									</span>
								</div>
							</div>
							<div className="modal-body">
								<div className="searching-for mb-5">
									<h4 className="fs-13 fw-normal text-gray-600 mb-3">I'm searching for...</h4>
									<div className="row g-1">
										<div className="col-md-4 col-xl-2">
											<a href="javascript:void(0);" className="d-flex align-items-center gap-2 px-3 lh-lg border rounded-pill">
												<i className="feather-compass" />
												<span>Recent</span>
											</a>
										</div>
										<div className="col-md-4 col-xl-2">
											<a href="javascript:void(0);" className="d-flex align-items-center gap-2 px-3 lh-lg border rounded-pill">
												<i className="feather-command" />
												<span>Command</span>
											</a>
										</div>
										<div className="col-md-4 col-xl-2">
											<a href="javascript:void(0);" className="d-flex align-items-center gap-2 px-3 lh-lg border rounded-pill">
												<i className="feather-users" />
												<span>Peoples</span>
											</a>
										</div>
										<div className="col-md-4 col-xl-2">
											<a href="javascript:void(0);" className="d-flex align-items-center gap-2 px-3 lh-lg border rounded-pill">
												<i className="feather-file" />
												<span>Files</span>
											</a>
										</div>
										<div className="col-md-4 col-xl-2">
											<a href="javascript:void(0);" className="d-flex align-items-center gap-2 px-3 lh-lg border rounded-pill">
												<i className="feather-video" />
												<span>Medias</span>
											</a>
										</div>
										<div className="col-md-4 col-xl-2">
											<a href="javascript:void(0);" className="d-flex align-items-center gap-2 px-3 lh-lg border rounded-pill">
												<span>More</span>
												<i className="feather-chevron-down" />
											</a>
										</div>
									</div>
								</div>
								<div className="recent-result mb-5">
									<h4 className="fs-13 fw-normal text-gray-600 mb-3">
										Recnet <span className="badge small bg-gray-200 rounded ms-1 text-dark">3</span>
									</h4>
									<div className="d-flex align-items-center justify-content-between mb-4">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-airplay fs-5" />
											<div className="fs-13 fw-semibold">CRM dashboard redesign</div>
										</a>
										<a href="javascript:void(0);" className="badge border rounded text-dark">
											/<i className="feather-command ms-1 fs-12" />
										</a>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-4">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-file-plus fs-5" />
											<div className="fs-13 fw-semibold">Create new eocument</div>
										</a>
										<a href="javascript:void(0);" className="badge border rounded text-dark">
											N /<i className="feather-command ms-1 fs-12" />
										</a>
									</div>
									<div className="d-flex align-items-center justify-content-between">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-user-plus fs-5" />
											<div className="fs-13 fw-semibold">Invite project colleagues</div>
										</a>
										<a href="javascript:void(0);" className="badge border rounded text-dark">
											P /<i className="feather-command ms-1 fs-12" />
										</a>
									</div>
								</div>
								<div className="command-result mb-5">
									<h4 className="fs-13 fw-normal text-gray-600 mb-3">
										Command <span className="badge small bg-gray-200 rounded ms-1 text-dark">5</span>
									</h4>
									<div className="d-flex align-items-center justify-content-between mb-4">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-user fs-5" />
											<div className="fs-13 fw-semibold">My profile</div>
										</a>
										<a href="javascript:void(0);" className="badge border rounded text-dark">
											P /<i className="feather-command ms-1 fs-12" />
										</a>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-4">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-users fs-5" />
											<div className="fs-13 fw-semibold">Team profile</div>
										</a>
										<a href="javascript:void(0);" className="badge border rounded text-dark">
											T /<i className="feather-command ms-1 fs-12" />
										</a>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-4">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-user-plus fs-5" />
											<div className="fs-13 fw-semibold">Invite colleagues</div>
										</a>
										<a href="javascript:void(0);" className="badge border rounded text-dark">
											I /<i className="feather-command ms-1 fs-12" />
										</a>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-4">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-briefcase fs-5" />
											<div className="fs-13 fw-semibold">Create new project</div>
										</a>
										<a href="javascript:void(0);" className="badge border rounded text-dark">
											CP /<i className="feather-command ms-1 fs-12" />
										</a>
									</div>
									<div className="d-flex align-items-center justify-content-between">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-life-buoy fs-5" />
											<div className="fs-13 fw-semibold">Support center</div>
										</a>
										<a href="javascript:void(0);" className="badge border rounded text-dark">
											SC /<i className="feather-command ms-1 fs-12" />
										</a>
									</div>
								</div>
								<div className="file-result mb-4">
									<h4 className="fs-13 fw-normal text-gray-600 mb-3">
										Files <span className="badge small bg-gray-200 rounded ms-1 text-dark">3</span>
									</h4>
									<div className="d-flex align-items-center justify-content-between mb-4">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-folder-plus fs-5" />
											<div className="fs-13 fw-semibold">
												CRM Desing Project <span className="fs-12 fw-normal text-muted">(56.74 MB)</span>
											</div>
										</a>
										<a href="javascript:void(0);" className="file-download">
											<i className="feather-download" />
										</a>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-4">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-folder-plus fs-5" />
											<div className="fs-13 fw-semibold">
												Admin Dashboard Project <span className="fs-12 fw-normal text-muted">(46.83 MB)</span>
											</div>
										</a>
										<a href="javascript:void(0);" className="file-download">
											<i className="feather-download" />
										</a>
									</div>
									<div className="d-flex align-items-center justify-content-between">
										<a href="javascript:void(0);" className="d-flex align-items-start gap-3">
											<i className="feather-folder-plus fs-5" />
											<div className="fs-13 fw-semibold">
												CRM Dashboard Project <span className="fs-12 fw-normal text-muted">(68.59 MB)</span>
											</div>
										</a>
										<a href="javascript:void(0);" className="file-download">
											<i className="feather-download" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default PenyusutanBahanKotor;
