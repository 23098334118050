import React from "react";

const ModalImage = ({ src, alt, onClose }) => (
	<div style={styles.overlay} onClick={onClose}>
		<div style={styles.modal} onClick={(e) => e.stopPropagation()}>
			<img src={src} alt={alt} style={{ width: "100%" }} />
			<button onClick={onClose} style={styles.closeButton}>
				Tutup
			</button>
		</div>
	</div>
);

const styles = {
	overlay: {
		position: "fixed",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	modal: {
		backgroundColor: "#fff",
		padding: "20px",
		borderRadius: "8px",
		position: "relative",
		width: "80%",
		maxWidth: "500px",
	},
	closeButton: {
		position: "absolute",
		top: "10px",
		right: "10px",
		backgroundColor: "red",
		color: "#fff",
		border: "none",
		padding: "5px",
		cursor: "pointer",
	},
};

export default ModalImage;
